import {
  Avatar,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { generateWhatsappURL } from "../services/contact.service";

const AuthorInfo = ({ author, handleClose = () => {} }) => {
  return (
    <>
      <Stack alignItems="center" sx={{ m: 2 }}>
        <Avatar
          {...(author.profile && { src: author.profile })}
          sx={{ width: 100, height: 100, fontSize: "xxx-large" }}
        >
          {!author.profile && author.name.substring(0, 1)}
        </Avatar>

        <Typography align="center" variant="h5">
          {author.name}
        </Typography>

        <Stack direction="row">
          {[
            ...(author.email
              ? [
                  {
                    label: "Email",
                    icon: MailOutlineIcon,
                    url: `mailto:${author.email}`,
                  },
                ]
              : []),
            ...(author.instagram
              ? [
                  {
                    label: "Instagram",
                    icon: InstagramIcon,
                    url: author.instagram,
                  },
                ]
              : []),
            ...(author.facebook
              ? [
                  {
                    label: "Facebook",
                    icon: FacebookIcon,
                    url: author.facebook,
                  },
                ]
              : []),
            ...(author.whatsapp
              ? [
                  {
                    label: "Whatsapp",
                    icon: WhatsAppIcon,
                    url: generateWhatsappURL(author.whatsapp),
                  },
                ]
              : []),
            ...(author.web
              ? [
                  {
                    label: "Web",
                    icon: LanguageIcon,
                    url: author.web,
                  },
                ]
              : []),
          ].map((option) => (
            <Tooltip title={option.label}>
              <IconButton component="a" href={option.url} target="_blank">
                <option.icon fontSize="small" />
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default AuthorInfo;
