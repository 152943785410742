import { Box } from "@mui/material";
import { useEffect } from "react";

const Hyperfocal = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//photopills.com/widgets/pphyperfocal.min.js?lang=es";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <Box sx={{ width: "100%" }} id="pphyperfocal"></Box>;
};

export default Hyperfocal;
