import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import SunCalc from "suncalc2";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import { DatePicker } from "@mui/x-date-pickers";

function time(date) {
  return moment(date).format("HH:mm:ss");
}

const Times = (props) => {
  const moonTimes = SunCalc.getMoonTimes(
    props.date,
    props.geolocation.lat,
    props.geolocation.lng
  );

  const moonIllumination = SunCalc.getMoonIllumination(props.date);

  const phase = moonIllumination.phase; // This will give a value between 0 and 1

  // Calculate human-readable moon phase
  const moonPhases = [
    "Luna nueva",
    "Luna creciente",
    "Cuarto creciente",
    "Luna creciente gibosa",
    "Luna llena",
    "Luna menguante gibosa",
    "Cuarto menguante",
    "Luna menguante",
  ];
  const index = Math.round(phase * (moonPhases.length - 1));
  const humanReadablePhase = moonPhases[index];

  return (
    <>
      <ul>
        <li>Salida: {time(moonTimes.rise)}</li>
        <li>Puesta: {time(moonTimes.set)}</li>
      </ul>

      <ul>
        <li>Fase lunar: {humanReadablePhase}</li>
      </ul>
    </>
  );
};

const Moon = () => {
  const [date, setDate] = useState(moment(new Date()));
  const [latitude, setLatitude] = useState(() => {
    const latitude = localStorage.getItem("latitude");
    if (!latitude) {
      return 0;
    }

    return parseFloat(latitude);
  });
  const [longitude, setLongitude] = useState(() => {
    const longitude = localStorage.getItem("longitude");
    if (!longitude) {
      return 0;
    }

    return parseFloat(longitude);
  });

  const setLatitudeInLocalStorage = (value) => {
    setLatitude(value);
    localStorage.setItem("latitude", `${value}`);
  };

  const setLongitudeInLocalStorage = (value) => {
    setLongitude(value);
    localStorage.setItem("longitude", `${value}`);
  };

  const onClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitudeInLocalStorage(position.coords.latitude);
      setLongitudeInLocalStorage(position.coords.longitude);
    });
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Luna
      </Typography>

      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format="DD/MM/YYYY"
            value={date}
            onChange={(newDate) => setDate(newDate)}
          />
        </LocalizationProvider>

        <Button
          variant="contained"
          color="primary"
          startIcon={<MyLocationIcon />}
          onClick={onClick}
        >
          Mi ubicación
        </Button>
      </Stack>

      <Typography variant="caption">
        {`Latitud: ${latitude} - Longitud: ${longitude}`}
      </Typography>

      <Times
        date={date.toDate()}
        geolocation={{ lat: latitude, lng: longitude }}
      />
    </Box>
  );
};

export default Moon;
