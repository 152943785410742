import { useEffect, useState } from "react";
import { getAuthors } from "../services/data.service";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const MembersLabels = () => {
  const [authors, setAuthors] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAuthors().then((authors) => {
      setAuthors(authors);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}
      {authors && (
        <Box
          sx={{
            m: 2,
            display: "flex",
            gap: 2,
            maxWidth: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {Object.entries(authors).map(([id, author]) => (
            <Box
              component={Paper}
              variant="outlined"
              sx={{ width: "500px", height: "230px" }}
            >
              <Stack>
                <Divider
                  sx={{
                    border: "3px solid",
                    borderImage:
                      "linear-gradient(to right, #ee217e 25%, #ffff01 25%, #ffff01 50%,#3ab6fe 50%, #3ab6fe 75%, #c768e0 75%) 5;",
                  }}
                />
                <Stack direction="row" sx={{ minHeight: "165px" }}>
                  <Stack sx={{ flexGrow: 1, mt: 1, ml: 1 }}>
                    <Typography variant="caption">Autor/a</Typography>
                    <Typography
                      variant="h5"
                      sx={{ flexGrow: 1, fontWeight: "bold" }}
                    >
                      {author.name}
                    </Typography>

                    <List dense>
                      <ListItem disablePadding>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="fotonoba.ar" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                          <InstagramIcon />
                        </ListItemIcon>
                        <ListItemText primary="@fotonobaok" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                          <FacebookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Encuentros FOTONOBA" />
                      </ListItem>
                    </List>
                  </Stack>

                  <Divider
                    orientation="vertical"
                    flexItem
                  />

                  <Stack sx={{ mt: 1, maxWidth: "150px", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ ml: 1 }} align="center">
                      Escaneá para ver más información del autor!
                    </Typography>

                    <img
                      alt="qr code"
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=fotonoba.ar/author/${id}`}
                      height={100}
                      width={100}
                    />
                  </Stack>
                </Stack>

                <Divider />

                <Stack sx={{ m: 1 }} direction="row">
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography
                      sx={{ fontFamily: "Futuris", fontWeight: "bold" }}
                      color="primary"
                    >
                      FOTONOBA
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontFamily: "Pilcrow" }}
                    >
                      FOTÓGRAFOS DEL NOROESTE DE LA PCIA. DE BUENOS AIRES
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default MembersLabels;
