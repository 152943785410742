export const contacts = {
  vacosta: {
    name: "Viviana Acosta",
    tel: "5492364675390",
  },
  pcorrea: {
    name: "Patricia Correa",
    tel: "5492364509486",
  },
};

export const whatsappURL = "https://api.whatsapp.com/send";

export const mapsURL = "http://maps.google.com/?q=";

export const messages = {
  eventInfo: (eventTitle) =>
    `Me gustarìa recibir más información sobre el evento ${eventTitle}`,
  shareAlbumMessage: (albumName) => `Te invito a ver el álbum "${albumName}" de FOTONOBA!`,
};
