import { CircularProgress, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { getAuthors } from "../services/data.service";
import { useParams } from "react-router";
import AuthorInfo from "./AuthorInfo";

const Author = () => {
  const [authors, setAuthors] = useState({});
  const [loading, setLoading] = useState(false);

  const { authorID } = useParams();

  useEffect(() => {
    setLoading(true);
    getAuthors().then((authors) => {
      setAuthors(authors);
      setLoading(false);
    });
  }, []);
  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}
      {authors && authors[authorID] && (
        <Paper variant="outlined" sx={{ px: 5, pb: 5 }}>
          <AuthorInfo author={authors[authorID]} />
        </Paper>
      )}
    </>
  );
};

export default Author;
