import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton, Tooltip } from "@mui/material";

const SocialMedia = () => {
  return (
    <>
      <Tooltip title="Correo electrónico para realizar cualquier tipo de consultas">
        <IconButton
          component="a"
          href="mailto:fotonobaok@gmail.com"
          target="_blank"
          sx={{ color: "#c71610" }}
        >
          <MailOutlineIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Canal de whatsapp donde publicamos novedades de todas las actividades">
        <IconButton sx={{ color: "#25d366" }}>
          <WhatsAppIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Grupo de Facebook donde compartimos fotos, aportamos para la consigna o simplemente intercambiamos trucos y consejos">
        <IconButton
          component="a"
          href="https://www.facebook.com/share/g/DXdAAqJ8Y9rTzvXV/"
          target="_blank"
          sx={{ color: "#1877F2" }}
        >
          <FacebookIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="En Instagram compartimos novedades sobre las salidas, resukltados de las consignas, y más!">
        <IconButton
          component="a"
          href="https://www.instagram.com/fotonoba.ok/"
          target="_blank"
          sx={{ color: "#c32aa3" }}
        >
          <InstagramIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SocialMedia;
