import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { contacts } from "../vars";
import { send } from "../services/contact.service";
import { useLocation } from "react-router";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Contact = () => {
  const query = useQuery();
  const message = query.get("message");

  const [name, setName] = useState("");
  const [text, setText] = useState(message ? message : "");
  const [contact, setContact] = useState("");

  const [nameError, setNameError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [contactError, setContactError] = useState(false);

  const handleSubmit = () => {
    setNameError(name === "");
    setTextError(text === "");
    setContactError(contact === "");

    if (name && text && contact) {
      send(name, text, contact);
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{ width: "70%" }}>
        <Typography variant="h5" align="center">
          Envíanos tus consultas!
        </Typography>
        <TextField
          id="nombre"
          label="Nombre"
          required
          error={nameError}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="consulta"
          label="Consulta"
          required
          error={textError}
          multiline
          rows={6}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <FormControl error={contactError}>
          <FormLabel>Representante de FOTONOBA</FormLabel>
          <RadioGroup
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          >
            {Object.entries(contacts).map(([key, value]) => (
              <FormControlLabel
                value={key}
                control={<Radio />}
                label={value.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          size="large"
          startIcon={<SendIcon />}
          onClick={handleSubmit}
          color="lila"
        >
          Enviar
        </Button>
      </Stack>
    </>
  );
};

export default Contact;
