import { useEffect, useState } from "react";
import { getAuthors } from "../services/data.service";
import {
  CircularProgress,
  Grid2 as Grid,
  Paper,

} from "@mui/material";

import AuthorInfo from "./AuthorInfo";

const Members = () => {
  const [authors, setAuthors] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAuthors().then((authors) => {
      setAuthors(authors);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}
      {authors && (
        <Grid
          sx={{ m: 2 }}
          container
          spacing={2}
          columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
        >
          {Object.entries(authors).map(([id, author]) => (
            <Grid size={1} component={Paper} variant="outlined">
              <AuthorInfo author={author} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Members;
