import { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Fab,
  Grid2 as Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import useBreakpoint from "../hooks/useBreakPoint";
import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {
  Fullscreen,
  Slideshow,
  Zoom,
  Captions,
} from "yet-another-react-lightbox/plugins";
import { getActivities, getAlbums, getAuthors } from "../services/data.service";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate, useParams } from "react-router";
import { share } from "../services/share.service";

//import { RowsPhotoAlbum } from "react-photo-album";
//import "react-photo-album/rows.css";

import "../assets/ImgNoClick.css";
import { messages } from "../vars";
import AuthorInfo from "./AuthorInfo";

const AuthorAction = ({ author }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ImageListItemBar
        sx={{ alignItems: "center" }}
        title={
          <Typography
            component="span"
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={handleClick}
          >
            {author.name}
          </Typography>
        }
        actionIcon={
          <IconButton onClick={handleClick}>
            <Avatar
              {...(author.profile && { src: author.profile })}
            >
              {!author.profile && author.name.substring(0, 1)}
            </Avatar>
          </IconButton>
        }
        //position="below"
      ></ImageListItemBar>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <AuthorInfo author={author} />
      </Menu>
    </>
  );
};

const Photo = ({ photo, onClick, authors }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoaded(true);
    img.src = photo.src;
  }, [photo.src]);

  return (
    <ImageListItem
    /*sx={{
        background: "#fff",
        pt: "1rem",
        px: "1rem",
        border: "1px solid darkgrey",
        boxShadow: "5px 10px 10px darkgrey",
      }}*/
    >
      {!loaded ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={150}
        ></Skeleton>
      ) : (
        <img
          srcSet={photo.srcSet}
          src={photo.src}
          alt={photo.alt}
          loading="lazy"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        />
      )}
      <AuthorAction author={authors[photo.author]} />
    </ImageListItem>
  );
};

const PhotoGallery = ({ photos, authors }) => {
  const breakpoint = useBreakpoint();
  const cols = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  }[breakpoint];

  const [index, setIndex] = useState(-1);

  return (
    <>
      <ImageList variant="masonry" cols={cols} gap={5} sx={{ m: 2 }}>
        {photos
          .map(({ thumbnail, alt, author, activity }) => ({
            src: thumbnail,
            alt: alt,
            author: author,
            activity: activity,
          }))
          .map((item, index) => (
            <Photo
              photo={item}
              onClick={() => setIndex(index)}
              authors={authors}
            />
          ))}
      </ImageList>

      <Lightbox
        index={index}
        slides={photos.map(({ full, alt, author }) => ({
          src: full,
          alt: alt,
          description: (
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              {authors[author].profile ? (
                <Avatar src={authors[author].profile} />
              ) : (
                <Avatar>{authors[author].name.substring(0, 1)}</Avatar>
              )}
              <Stack>
                <Typography align="left">{authors[author].name}</Typography>
                <Typography align="left" variant="caption">
                  {alt}
                </Typography>
              </Stack>
            </Stack>
          ),
        }))}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom, Captions]}
        captions={{
          showToggle: false,
          descriptionTextAlign: "center",
          descriptionMaxLines: 1,
        }}
        carousel={{
          spacing: 0,
          padding: 0,
        }}
      />
    </>
  );
};

export default function Gallery() {
  const breakpoint = useBreakpoint();
  const cols = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  }[breakpoint];

  const [albums, setAlbums] = useState([]);
  const [authors, setAuthors] = useState({});
  const [activities, setActivities] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { albumName } = useParams();

  const [selectedAlbum, setSelectedAlbum] = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([getAlbums(), getAuthors(), getActivities()]).then(
      ([albums, authors, activities]) => {
        setAlbums(albums);
        setAuthors(authors);
        setActivities(activities);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (albumName) {
      let album = albums.find((a) => a.name === albumName);
      setSelectedAlbum(album);
    } else {
      setSelectedAlbum(null);
    }
  }, [albums, albumName]);

  return (
    <>
      {/*<RowsPhotoAlbum
        photos={photos.map((photo) => ({ ...photo, width: 1, height: 1 }))}
      />*/}

      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      {selectedAlbum ? (
        <>
          <Breadcrumbs sx={{ my: 2 }}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => navigate("/gallery")}
            >
              <PhotoLibraryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Álbumes
            </Link>
            <Typography
              sx={{
                color: "text.primary",
                display: "flex",
                alignItems: "center",
              }}
            >
              {activities[selectedAlbum.name].name}
            </Typography>
          </Breadcrumbs>

          <Typography
            variant="h6"
            sx={{
              minWidth: "50%",
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            {activities[selectedAlbum.name].description}
          </Typography>

          <PhotoGallery photos={selectedAlbum.photos} authors={authors} />

          <Fab
            onClick={() =>
              share(
                messages.shareAlbumMessage(activities[selectedAlbum.name].name),
                activities[selectedAlbum.name].name
              )
            }
            color="secondary"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
            }}
          >
            <ShareIcon />
          </Fab>
        </>
      ) : (
        <Grid container spacing={0.5} sx={{ m: 2 }} columns={cols}>
          {albums.map((a) => (
            <Grid size={1}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardActionArea onClick={() => navigate(`/gallery/${a.name}`)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    {a.photos.slice(0, 4).map((p, index) => (
                      <div
                        style={{
                          width: "50%",
                          textAlign: "center",
                          position: "relative",
                          color: "white",
                        }}
                      >
                        <img
                          src={p.thumbnail}
                          alt={p.alt}
                          loading="lazy"
                          style={{
                            "aspect-ratio": "1",
                            width: "100%",
                            height: "100%",
                            "object-fit": "cover",
                          }}
                        />
                        {index === 3 && a.photos.length > 4 && (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                backgroundColor: "black",
                                opacity: 0.4,
                                height: "100%",
                                width: "100%",
                              }}
                            ></div>
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                fontSize: "50px",
                              }}
                            >
                              +{`${a.photos.length - 4}`}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </Box>
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="h6">
                      {activities[a.name].name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption">
                        {`${a.photos.length} fotos`}
                      </Typography>
                      <AvatarGroup
                        total={
                          [...new Set(a.photos.map((p) => p.author))].length
                        }
                        slotProps={{
                          additionalAvatar: {
                            sx: { width: 24, height: 24, fontSize: "small" },
                          },
                        }}
                      >
                        {[...new Set(a.photos.map((p) => p.author))].map(
                          (author) =>
                            authors[author].profile ? (
                              <Avatar
                                sx={{ width: 24, height: 24 }}
                                alt={authors[author].name}
                                src={authors[author].profile}
                              />
                            ) : (
                              <Avatar
                                sx={{ width: 24, height: 24, fontSize: "12px" }}
                              >
                                {authors[author].name.substring(0, 1)}
                              </Avatar>
                            )
                        )}
                      </AvatarGroup>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
